<template>
    <div class="setting-app">
        <div style="width: 100%; display: flex; justify-content: center; align-items: center" v-for="item in deskTopAppList" :key="item.key">
            <div class="app-item" v-if="!item.hideInDesktop">
                <div class="icon">
                    <i
                        :style="{
                            backgroundColor: item.iconBgColor,
                            color: item.iconColor
                        }"
                        class="iconfont"
                        :class="item.icon"
                    ></i>
                </div>
                <div class="title">{{ item.title }}</div>
            </div>

            <div class="item" style="width: 650px" v-if="!item.hideInDesktop">
                <input style="width: 555px" placeholder="请输入完整的服务器地址" type="text" v-model="urlModel[item.key]" />
                <i class="login-button click-enable iconfont icon-queren" @click="setWebUrl(item.key)"></i>
            </div>
        </div>
    </div>
    <span style="padding: 5px; font-size: 12px; color: #333">请配置完整的服务器地址，包括：协议，主机名，端口，路径等</span>
</template>

<script>
export default {
    data() {
        return {
            deskTopAppList: [],
            urlModel: {}
        };
    },
    methods: {
        // 设置服务器地址
        setWebUrl(key) {
            const urlPattern =
                /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

            const match = this.urlModel[key].match(urlPattern);

            if (match && (this.urlModel[key].startsWith('http://') || this.urlModel[key].startsWith('https://'))) {
                localStorage.setItem(key, this.urlModel[key]);
                this.$message.success('配置成功');
            } else {
                this.$message.warning('请配置正确的服务器地址');
            }
        }
    },
    created() {
        this.deskTopAppList = this.tool.getDeskTopApp();

        this.deskTopAppList.forEach((item) => {
            if (item.key.startsWith('ht_')) {
                // 优先读取缓存
                const value = localStorage.getItem(item.key);

                if (value) {
                    this.urlModel[item.key] = value;
                } else {
                    this.urlModel[item.key] = item.url;
                }
            }
        });
    }
};
</script>

<style scoped lang="scss">
.setting-app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 20px;
    flex-wrap: wrap-reverse;
    background: #356ed1;
    .app-item {
        padding: 10px 0px;
        flex-direction: column;
        text-align: center;
        text-shadow: 0px 0px 2px rgb(0 0 0 / 50%);
        border-radius: 10px;
        border: 2px solid transparent;
        justify-content: center;
        align-items: center;
        width: 80px;
        .icon {
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .iconfont {
            font-size: 28px;
            border-radius: 10px;
            padding: 8px;
        }
        .title {
            font-size: 12px;
            margin-top: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .item {
        vertical-align: middle;
        position: relative;
        width: 250px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        input {
            color: white;
            outline: none;
            border: none;
            margin: 5px;
            font-size: 16px;
            background-color: rgba(255, 255, 255, 0.3);
            padding: 8px 24px;
            border-radius: 20px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            width: 100%;
        }

        input::placeholder {
            color: #e3e3e3;
        }

        .iconfont {
            vertical-align: middle;
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
            border-radius: 100%;
            width: 36px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        }
        .iconfont:hover {
            background-color: rgba(255, 255, 255, 0.5);
        }
        .click-enable {
            right: 0;
        }
    }
}
</style>
